import { Navbar } from "@blueprintjs/core/lib/esm/components/navbar/navbar";
import { NavbarGroup } from "@blueprintjs/core/lib/esm/components/navbar/navbarGroup";
import { Button, Divider, Icon } from "@blueprintjs/core";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { UserProfilePopover } from "./UserProfilePopover";
import { EventNotificationPopover } from "./EventNotificationPopover";
import { useUserStore } from "../stores/UserStore";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import "../styles/Breadcrumbs.css"
import { useOrganismStore } from "../stores/OrganismStore";
import { useOrganizationStore } from "../stores/OrganizationStore";
import { Toaster } from "../utils/Toaster";

export function LandingPage() {

    const { user } = useUserStore()
    const {setOrganization, organizationList} = useOrganizationStore()

    useEffect(() => {
        if (user?.organization.length === 1 && !user?.role.includes('Admin')) {
            window.location.href = `/${user?.organization[0].toLowerCase()}`
        }
    }, [user])

    const onClick_organizationButton = (organizationName: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {        
        const organization = organizationList?.find(orgz => orgz.organizationName === organizationName)
        if (organization)
            setOrganization(organization)
        else{
            event.preventDefault()
            Toaster.show({ icon: "error", message: `Organization not found: ${organizationName}`, intent: "danger" })
        }            
    }

    return (
        <div className="grid-wrapper">
            <div className='grid-header'>
                <Navbar style={{ backgroundColor: "#3c84fb" }}>
                    <NavbarGroup align={'left'}>
                        <NavLink style={{ textDecoration: 'none', color: 'black', outline: 'none' }} to="/"><Button icon="home" minimal title="Site Homepage"></Button></NavLink>
                        <Divider style={{ height: '50%', margin: '10px' }} />
                        <div className="bp4-navbar-heading">Select an Organization</div>
                        <div className="bp4-navbar-heading">{window.parsedConfig.DEPLOY_ENVIRONMENT.toUpperCase()} {window.parsedConfig.DEPLOY_VERSION.toUpperCase()}</div>
                    </NavbarGroup>
                    <NavbarGroup align={'right'}>
                        <EventNotificationPopover />
                        <UserProfilePopover />
                    </NavbarGroup>
                </Navbar>
            </div>
            {organizationList?.length ?
                <div
                    className="grid-main"
                    style={{ marginTop: '25px', marginLeft: '25px' }}>
                    {
                        user?.organization.map((organization) => {
                            const link = `/${organization.toLowerCase()}${organization.toLowerCase().includes('calicinet') ? "/norovirus" : ""}`
                            return (
                                <NavLink key={organization} to={link} onClick={(e) => {                                    
                                    onClick_organizationButton(organization, e)
                                }}>
                                    <Button style={{ height: '140px', width: '200px', margin: '20px' }} large={true}>{organization}</Button>
                                </NavLink>
                            )
                        })

                    }
                    {
                        (user?.role.includes('Admin')) &&
                        <NavLink key={'admin'} to={`/admin`}>
                            <Button style={{ height: '140px', width: '200px', margin: '20px' }} large={true}>Admin</Button>
                        </NavLink>
                    }
                </div>
                :
                <div style={{ position: 'absolute', top: "20%", left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Spinner size={55}></Spinner>
                    <h2>Loading Your Organizations...</h2>
                </div>
            }

        </div>
    )
}