export function convertNDBQuery(query: string, organismName: string, organizationName: string, basedIndexedFields: string[], baseCriteria?: any){
  query = query.replaceAll("rules", "criteria")
  query = query.replaceAll("combinator", "operator")
  query = query.replaceAll('>=', "gte")
  query = query.replaceAll('<=', "lte")
  query = query.replaceAll('>', "gt")
  query = query.replaceAll('<', "lt")
  query = query.replaceAll('=', "equals")
  query = organizationName.toLowerCase().includes('calicinet') ? query.replaceAll('data.metadata.', "metadata.") : query.replaceAll('data.metadata.', "")

  let criteria = (JSON.parse(query)).criteria.map((criteria: any) => {
    if (criteria.operator === "!equals") {
      return {
        operator: "not",
        criterion: {
          field: criteria.field,
          operator: "equals",
          value: criteria.value
        }
      }
    } else if (criteria.operator === "exists") {
      return organizationName.toLowerCase().includes('calicinet') ? {
        operator: "and",
        criteria: [
          {
            field: "data.metadata." + criteria.field,
            operator: "exists"
          }
        ]
      } 
      :
      {
        operator: "and",
        criteria: [
          {
            field: "data.metadata." + criteria.field,
            operator: "exists"
          },
          {
            operator: "not",
            criterion: {
              field: criteria.field,
              operator: "equals",
              value: ""
            }
          },

        ]
      }
    } else {
      return criteria
    }
  })
  let base = undefined;
  if (criteria.length)
    base = {
      "organism": organismName,
      "criteria": baseCriteria
        ? {
          operator: "and",
          criteria: [
            baseCriteria,
            {
              criteria: criteria,
              operator: (JSON.parse(query)).operator
            }
          ]
        }
        : {
          criteria: criteria,
          operator: (JSON.parse(query)).operator
        }
    }
  else
    base = {
      "organism": organismName,
      "criteria": baseCriteria
        ? {
          operator: "and",
          criteria: [ baseCriteria]
        }
        : {
          criteria: criteria,
          operator: (JSON.parse(query)).operator
        }
    }
  
  if (!organizationName.toLowerCase().includes('calicinet')) {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 6);
    let formattedDate = currentDate.toISOString().slice(0, 10);

    if (base.criteria && base.criteria.criteria && Array.isArray(base.criteria?.criteria))
      if (!criteria.length)
        base.criteria.criteria = [{
          "field": basedIndexedFields[0],
          "operator": "gte",
          "value": formattedDate
      }, ...base.criteria.criteria]
  }
  
  // if (baseCriteria)
  //   base.criteria.criteria.push(baseCriteria)
  return JSON.stringify(base)
}