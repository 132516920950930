import { View, ViewQuery } from "../types/ViewTypes";
import { OutbreakAPI, SampleAPI } from '../api/DataAPI';
import { useAuth } from "react-oidc-context";
import { useInfiniteQuery } from "@tanstack/react-query";
import { CellEdits } from "../components/EditDataViewer";
import { useOrganismStore } from "../stores/OrganismStore";
import { useDataViewerCtrStore } from "../stores/DataViewerCtrStore";
import { useOrganizationStore } from "../stores/OrganizationStore";
import { SearchResponse } from "../types/SampleTypes";
import { convertNDBQuery } from "../utils/convertNDBQuery";
import { useUserStore } from "../stores/UserStore";

export function useSampleInfiniteQuery(selectedView: View | undefined, sort?: string, cellEdits?: CellEdits) {
  const auth = useAuth();
  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")

  const { organism } = useOrganismStore()
  const { organization } = useOrganizationStore();
  const { columnFilters } = useDataViewerCtrStore()
  const { user } = useUserStore()

  const parseMongoColumnFilters = (query: string) => {
    // Parse query string to object
    const parsedQuery = JSON.parse(query)
    // Handle and query that already has an array
    if (parsedQuery["$and"]) {
      const query: [{ [key: string]: any }] = parsedQuery["$and"]
      Object.keys(columnFilters).forEach(filter => {
        const newQueryObject: { [key: string]: any } = {}
        newQueryObject[filter] = { "$regex": columnFilters[filter] }
        query.push(newQueryObject)
      })
      parsedQuery["$and"] = query
      return JSON.stringify(parsedQuery)
    }
    // Handle or query that already has an array
    else if (parsedQuery["$or"]) {
      const newQueryArray = []
      const orObject: { [key: string]: any } = {}
      orObject["$or"] = parsedQuery["$or"]
      newQueryArray.push(orObject)

      Object.keys(columnFilters).forEach(filter => {
        const newQueryObject: { [key: string]: any } = {}
        newQueryObject[filter] = { "$regex": columnFilters[filter] }
        newQueryArray.push(newQueryObject)
      })
      return JSON.stringify({ "$and": newQueryArray })
    }
    // Handle a query that is only looking at a single field
    else {
      const queryArray: any[] = []
      Object.keys(parsedQuery).forEach(field => {
        const newObject: { [key: string]: any } = {}
        newObject[field] = parsedQuery[field]
        queryArray.push(newObject)
      })
      Object.keys(columnFilters).forEach(filter => {
        const newQueryObject: { [key: string]: any } = {}
        newQueryObject[filter] = { "$regex": columnFilters[filter] }
        queryArray.push(newQueryObject)
      })
      return JSON.stringify({ "$and": queryArray })
    }
  }

  const parseSQLColumnFilters = (query: string) => {
    // Parse the query back to JS Object
    const parsedQuery = JSON.parse(query)

    // Handle AND query
    if (parsedQuery["combinator"] === "and") {
      // Create copy of current rules
      const rules = parsedQuery["rules"]
      // Add filter rules
      Object.keys(columnFilters).forEach(column => {
        rules.push({ field: column, value: columnFilters[column], operator: "contains", valueSource: "value" })
      })
      // Return query with overwritten rules that include query rules
      return JSON.stringify({ ...parsedQuery, rules })
    }
    // Handle OR query
    else {
      const newQueryObject: { [key: string]: any } = {}
      newQueryObject["rules"] = []
      newQueryObject["rules"].push({ ...parsedQuery, not: "false" })
      newQueryObject["combinator"] = "and"
      Object.keys(columnFilters).forEach(column => {
        newQueryObject["rules"].push({ field: column, value: columnFilters[column], operator: "contains", valueSource: "value" })
      })
      return JSON.stringify(newQueryObject)
    }
  }

  const fetchView = async (selectedView: View | undefined, pageParam: string) => {
    if (!selectedView)
      throw (new Error("Cannot fetch view. No view selected."))

    const sampleAPI = new SampleAPI(auth.user?.access_token ?? "")
    const outbreakAPI = new OutbreakAPI(auth.user?.access_token ?? "")

    let data;
    if (selectedView.nationalDatabase) {
      let updatedQuery;
      if (Object.keys(columnFilters).length > 0) {
        updatedQuery = parseSQLColumnFilters(selectedView.query)
      }
      let basedIndexedFields = organization?.organizationName.toLowerCase().includes('calicinet') ? [] : ["PulseNet_UploadDate", "IsolatDate", "LabID", "SEROTYPE_WGS", "SourceState", "ALLELE_CODE", "Outbreak", "REP_CODE", "ReceivedDate"]
      let baseCriteria = organization?.organizationName.toLowerCase().includes('calicinet') ? (!user?.role.includes("DatabaseManager") ? {
        "criteria": [
          {
            "field": "identifier",
            "operator": "exists"
          },
          {
            "operator": "not",
            "criterion": {
              "field": "metadata.ConfirmStatus",
              "operator": "equals",
              "value": "Pending"
            }
          }
        ],
        "operator": "and"
      } :
        {
          "field": "identifier",
          "operator": "exists"
        })
        :
        selectedView.hasAnalyses ? {
          "field": "analyses.wgmlst.audit.updatedAt",
          "operator": "notNull"
        }
          :
          null
      data = await sampleAPI.query_ndb(
        convertNDBQuery(updatedQuery ?? selectedView.query, organism?.name ?? "", organization!.organizationName, basedIndexedFields, baseCriteria),
        undefined, undefined, undefined, pageParam, selectedView.isOutbreak, false
      );
    } else if (selectedView.isOutbreak) {
      const query: ViewQuery = {
        QueryString: selectedView.query,
        isCaseSensitive: selectedView.caseSensitive ?? true,
        organism: organism?.name ?? "",
        organization: organization?.organizationName ?? ""
      };
      data = await outbreakAPI.search(query, undefined, undefined, undefined, pageParam)
    } else {
      let updatedQuery;
      if (Object.keys(columnFilters).length) {
        updatedQuery = parseMongoColumnFilters(selectedView.query)
      }
      const query: ViewQuery = {
        QueryString: updatedQuery || selectedView.query,
        isCaseSensitive: selectedView.caseSensitive ?? true,
        organism: organism?.name ?? "",
        organization: organization?.organizationName ?? ""
      };
      data = await sampleAPI.search(query, undefined, undefined, undefined, pageParam)
    }
    return data
  }

  const getNextPageParam = (lastPage: SearchResponse, pages: SearchResponse[]) => {
    if (lastPage.items.length) {
      let cursorJSON = JSON.parse(window.atob(lastPage.self))
      cursorJSON.page++
      let b64NextCursor = window.btoa(JSON.stringify(cursorJSON))
      return b64NextCursor
    }
    else
      return undefined
  }

  return useInfiniteQuery({
    queryKey: ['samples', sort, selectedView?.id, columnFilters, selectedView?.query],
    queryFn: ({ pageParam = getDefaultCursor(selectedView, sort) }) => fetchView(selectedView, pageParam),
    getNextPageParam: getNextPageParam,
    refetchInterval: selectedView?.nationalDatabase ? false : (cellEdits && Object.keys(cellEdits).length === 0 ? 20000 : false),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!sort
  })
}

const getDefaultCursor = (selectedView: View | undefined, sort?: string) => {
  if (!selectedView)
    throw (new Error("Cannot fetch view. No view selected."))

  return window.btoa(JSON.stringify({ "type": "page", "page": 0, "pageSize": 500, "sort": sort ?? "+identifier" }))
}
