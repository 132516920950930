import { useAuth } from "react-oidc-context";
import { ComparisonAPI } from "../api/DataAPI";
import { Comparison, ComparisonGroup } from "../types/ComparisonTypes";
import { useComparisonFormStore } from "../stores/ComparisonFormStore";
import { useAppStore } from "../stores/AppStore";
import { useComparisonStore } from "../stores/ComparisonStore";
import { useOpenComparisonsStore } from "../stores/OpenComparisonsStore";
import { useUserStore } from "../stores/UserStore";
import { useOrganismStore } from "../stores/OrganismStore";
import { Toaster } from "../utils/Toaster";
import { UseFormReset } from "react-hook-form";
import { useOrganizationStore } from "../stores/OrganizationStore";

interface ComparisonFormFunctions {
  onSubmit: (data: Comparison) => Promise<void>
  fetchData: () => Promise<void>
  updateComparedSamples: (index: number) => void
}

export const useComparisonFormFunctions = (reset: UseFormReset<Comparison>): ComparisonFormFunctions => {
  const auth = useAuth()
  const comparisonAPI = new ComparisonAPI(auth.user?.access_token ?? "")
  const { setLoading, setErrorMessage, showComparisonForm, setDataLoading, setAlert, setUpdatedComparisonSamples } = useComparisonFormStore()
  const { deleteFavoriteComparison, addFavoriteComparison } = useUserStore()
  const { selectedTabId, getTabById, tabs, closeTab, setComparison, setCurrentTabName, setUnsaved } = useOpenComparisonsStore()
  const { organism } = useOrganismStore()
  const { organization } = useOrganizationStore()
  const { setComparisons } = useComparisonStore()
  const { setShowComparisonForm } = useAppStore()
  const currentTab = getTabById(selectedTabId)
  const { comparison } = currentTab!
  //check if group name already exists
  const isGroupUnique = (groups: ComparisonGroup[]) => {
    if (groups === undefined) 
      return true;
    const nameSet = new Set();
    for (const group of groups) {
      if (nameSet.has(group.name)) {
        return false;
      }
      nameSet.add(group.name);
    }
    return true;
  }
  const resetForm = async () => {
    reset(comparison)
    setDataLoading(false)
  }

  //check if a comparison with the same name already exists
  const checkNameOfComparison = async (name: string) => {
    const allComparisons = await comparisonAPI.getComparisonsByOrganism(organism!.name)
    if (allComparisons.find(comparison => comparison.name.toLowerCase() === name.toLowerCase())) {
      return allComparisons.find(comparison => comparison.name.toLowerCase() === name.toLowerCase());
    }
    return false;
  }

  const onSubmit = async (data: Comparison) => {
    if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
      throw new Error("No authenticated user found.")
    setLoading(true);
    try {
      //check if group name already exists
      if (!isGroupUnique(data.groups)) {
        setErrorMessage("Name in Comparison Groups must be unique.")
        setLoading(false)
        return
      }

      if (!comparison.id) {
        //check if a comparison with the same name already exists
        const duplicateCheck = await checkNameOfComparison(data.name)
        if (duplicateCheck && duplicateCheck.id) {
          if (window.confirm("A comparison with this name already exists. Would you like to overwrite it?")) {
            await comparisonAPI.delete(duplicateCheck.id);
            deleteFavoriteComparison(duplicateCheck.id, auth.user?.access_token ?? "");
            const openTab = tabs.find(v => duplicateCheck.id === v.comparison.id)
            if (openTab)
              closeTab(openTab.id)
          }
        }
        data.organism = organism?.name ?? "";
        data.organization = organism?.organization ?? "";

        const savedComparison = (await comparisonAPI.insert(data))
        setComparisons(await comparisonAPI.getComparisonsByOrganism(organism!.name))
        //add to Favorites
        if (savedComparison.id) {
          await addFavoriteComparison(savedComparison.id, auth.user?.access_token ?? "", organism?.name, organization?.organizationName);
        }
        setComparison(savedComparison);
        setCurrentTabName(savedComparison.name)
      }
      else {
        const duplicateCheck = await checkNameOfComparison(data.name)
        if(duplicateCheck && data.name !== comparison.name){
          setErrorMessage("That name already exists.")
          setLoading(false)
        return
        }
        await comparisonAPI.update(comparison.id, data)
        const updatedComparison = await comparisonAPI.get(comparison.id)
        setComparison(updatedComparison)
        setCurrentTabName(updatedComparison.name)
      }

      Toaster.show({ icon: "tick", message: `Comparison Saved Successfully`, intent: "success" })
      setUnsaved(false)

    } catch (e) {
      Toaster.show({ icon: "error", message: `There was an error saving the comparison form.`, intent: "danger" })
    }
    setLoading(false);
    setShowComparisonForm(false)
  }

  const fetchData = async () => {
    if (showComparisonForm) {
      setDataLoading(true)
      await resetForm()
    }
    setUpdatedComparisonSamples(comparison.comparedSamples)
    setAlert(false); // Reset alert
    setErrorMessage(''); // Reset error message
  }

  const updateComparedSamples = (index: number) => {
    const currentComparison: Comparison = comparison
    const deletedGroupName = currentComparison.groups[index].name
    const updatedComparisonSamples = currentComparison.comparedSamples.map(el => {
      const updatedComparedSample = el
      if (updatedComparedSample.groupName === deletedGroupName) {
        updatedComparedSample.groupName = undefined
      }
      return updatedComparedSample
    })
    setUpdatedComparisonSamples(updatedComparisonSamples)
  }

  return { onSubmit, fetchData, updateComparedSamples }
}