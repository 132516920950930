import { useEffect } from "react"
import { useAppStore } from "../stores/AppStore"
import { useStartPipelineDialogStore } from "../stores/StartPipelineDialogStore"
import { PipelineAPI, SampleAPI } from "../api/DataAPI"
import { useAuth } from "react-oidc-context"
import { Toaster } from "../utils/Toaster"
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner"
import { Sample } from "../types/SampleTypes"
import { useOrganismStore } from "../stores/OrganismStore"
import { useOrganizationStore } from "../stores/OrganizationStore"
import { PipelineExecutionRequest, SampleFileMapping } from "../types/PipelineType"
import { useLabsStore } from "../stores/LabsStore"
import { useStartPipelineOnSelectedFunctions } from "./useStartPipelineOnSelectedFunctions"
import { usePipelinesStore } from "../stores/PipelinesStore"

interface StartPipelineDialogFunctions {
  handleCloseDialog: () => void
  handleCloseCalcDialog: () => void
}

export const useStartPipelineDialogFunctions = (): StartPipelineDialogFunctions => {
  const { 
    filesUploading,     
    setPipelineRun,    
    samples,
    setSamples,
    linkingFiles, 
    linkingComplete,
    setLinkingFiles,
    setLinkingComplete, 
    resetDialog,
    startPipeline,
    selectedTemplateName,
    selectedPipelineId
  } = useStartPipelineDialogStore()
  const { setSelectedPipelineRunId, setShowPipelineRunsDialog, setShowStartCalcPipelineDialog } = useAppStore()
  const { getSampleMappings } = useStartPipelineOnSelectedFunctions()
  const { setShowStartPipelineDialog } = useAppStore()
  // const { organism } = useOrganismStore()
  // const { organization } = useOrganizationStore()  
  // const { userLab } = useLabsStore()  
  const auth = useAuth();
  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")

  // const hasFileLinks = (samples: Sample[]) => samples.filter(v => v.inputFileLinks?.length).length > 0

  // const pendingFileLinks = (samples: Sample[]) => samples
  //   .map(v => (v.inputFileLinks ?? []).filter(filelink => filelink.status === "pending").length > 0)
  //   .filter(v => v).length > 0
  
  // const getLabAccessionFromFilename = (fileName: string) => {
  //   const regex = /^([A-Za-z0-9]+)(_*)/
  //   const match = fileName.match(regex)
  //   return match && match[1] ? match[1] : ''
  // }    

  // useEffect(() => {
  //   let interval: NodeJS.Timer
  //   try {
  //     interval = setInterval(async () => {
  //       const samplesAPI = new SampleAPI(auth.user?.access_token ?? "")
  //       const pipelineAPI = new PipelineAPI(auth.user?.access_token ?? "")
  //       if (!auth.isAuthenticated)
  //         throw (Error("Authentication has expired."))
  //       let newSamples = await samplesAPI.queryById(samples.map(v => v.id), organization?.organizationName ?? "");
  //       setSamples(newSamples)
  //       if (!filesUploading && hasFileLinks(newSamples) && !pendingFileLinks(newSamples)) {
  //         clearInterval(interval);
  //         setLinkingFiles(false)
  //         setLinkingComplete(true)
  //         if (startPipeline) {
  //           const template = userLab?.pipelineTemplates.find(v => v.name === selectedTemplateName && v.organism === organism?.name && v.organization === organization?.organizationName)
  //           const startPipelineBody = {
  //             SampleFileMappings: newSamples.reduce((pv, sample) => [...pv, ...sample.inputFileLinks!.map(inputfileLink => ({
  //               "SampleKey": organization?.useLabAccession ? getLabAccessionFromFilename(inputfileLink.name) : sample.identifier,
  //               "FileName": inputfileLink.name,
  //               "Source": "local",
  //               "RemoteURI": inputfileLink.storageURI
  //             }))], [] as SampleFileMapping[]),
  //             SampleMetadataMappings: template ? newSamples.map((sample, i) => ({
  //               "Identifier": sample.identifier,
  //               "Mappings": getSampleMappings(newSamples, template)[i]
  //             })) : [],
  //             // IsSanger: pipelines?.filter(pipeline => pipeline.id === selectedPipelineId)[0].pipelineName.toLowerCase().includes("sanger"),
  //             organization: organization?.organizationName ?? ""
  //           } as PipelineExecutionRequest

  //           Toaster.show({
  //             icon: "flows", 
  //             message: (               
  //               <div style={{ display: "flex", flexDirection: "row" }}>
  //                 <Spinner size={15}></Spinner>
  //                 <div>Starting Pipeline...</div>
  //               </div>
  //             ),
  //             intent: "primary",
  //             timeout: undefined
  //           }, "startFilelinkPipeline")

  //           try {
  //             const pipelineRun = await pipelineAPI.executePipeline(selectedPipelineId, startPipelineBody)
  //             setPipelineRun(pipelineRun)
  //             Toaster.dismiss("startFilelinkPipeline")
  //             Toaster.show({
  //               icon: "tick", message: `Pipeline started successfully.`, intent: "success", action: {
  //                 text: "View Pipeline",
  //                 icon: "flows",
  //                 onClick: () => {
  //                   setSelectedPipelineRunId(pipelineRun.id)
  //                   setShowPipelineRunsDialog(true)
  //                 },
  //               },
  //               timeout: 10000
  //             })
  //           }
  //           catch (e) {
  //             Toaster.show({ icon: "error", message: `An error occured when starting the pipeline.`, intent: "danger" })
  //           }

  //         }
  //       }
  //     }, 5000);
  //     if (!linkingFiles)
  //       clearInterval(interval);

  //     return () => clearInterval(interval);
  //   }
  //   catch (e) {
  //     Toaster.show({ icon: "error", message: `An error occured: ${e}`, intent: "danger" })
  //     return () => clearInterval(interval);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [linkingFiles, filesUploading, samples, auth])

  const handleCloseDialog = () => {
    if (!linkingFiles && !linkingComplete)
      resetDialog()
    setShowStartPipelineDialog(false)
  }

  const handleCloseCalcDialog = () => {
    if (!linkingFiles)
      resetDialog()
    setShowStartCalcPipelineDialog(false)
  }


  return { handleCloseDialog, handleCloseCalcDialog }
}