import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Dialog } from "@blueprintjs/core/lib/esm/components/dialog/dialog";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { Switch } from "@blueprintjs/core/lib/esm/components";
import { useEffect } from 'react';
import { useForm, useController, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import "../styles/FormStyle.css"
import "../styles/FormInput.css"
import { ComparedSample, Comparison } from '../types/ComparisonTypes';
import { useAppStore } from '../stores/AppStore';
import { SortContainer } from './controls/ViewFieldSortCtr';
import { ComparisonGroupControl } from './controls/ComparisonGroupControl';
import { useAuth } from "react-oidc-context";
import "../styles/ComparisonTable.css";
import { useOpenComparisonsStore } from "../stores/OpenComparisonsStore";
import { useComparisonFormStore } from "../stores/ComparisonFormStore";
import { useComparisonFormFunctions } from "../hooks/useComparisonFormFunctions";
import { getDateTimeString } from "../utils/getDateTimeString";

const yupSchema = yup.object().shape({
  name: yup.string().required(`Comparison Name is required.`)
    .max(40, "Comparison Name cannot exceed 40 characters"),
  columns: yup.array().min(1, "You must select a column."),
  groups: yup.array(yup.object().shape({
    name: yup.string().required("A group name is required.")
  }))
}).required()

let defaultValues = {
  id: "",
  name: "",
  columns: [],
  groups: [],
  shared: false,
  comparedSamples: [] as ComparedSample[]
}

export function ComparisonForm() {
  const { setShowComparisonForm, setSelectedSamples } = useAppStore()
  const { showComparisonForm, alert, loading, dataLoading, errorMessage, updatedGroups, setUpdatedGroups } = useComparisonFormStore()
  const { selectedTabId, getTabById } = useOpenComparisonsStore();
  const auth = useAuth();
  // if(!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")

  const form = useForm<Comparison>({
    mode: "onBlur",
    resolver: yupResolver(yupSchema),
    defaultValues: defaultValues
  });

  //useForm API https://react-hook-form.com/api/useform
  const { register, control, handleSubmit, reset, getValues, formState: { isValid, errors, submitCount } } = form;
  const columnsController = useController({ name: "columns", control })
  const sharedController = useController({ name: "shared", control })
  const groupsFieldArray = useFieldArray({ name: "groups", control })
  const { onSubmit, fetchData, updateComparedSamples } = useComparisonFormFunctions(reset)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComparisonForm])

  const currentTab = getTabById(selectedTabId)
  if (!currentTab)
    return <></>

  const formColumnStyle = { maxHeight: "60vh", overflowY: "auto" } as React.CSSProperties

  return <>
    <Dialog
      isOpen={showComparisonForm}
      title={`Comparison Form`}
      onClose={() => {
        setSelectedSamples([])
        setShowComparisonForm(false)
      }}
      style={{ width: "1040px" }}
      className="comparison-form"
    >
      {dataLoading ? <div className='loading-ctr-comparison'><Spinner size={40}></Spinner></div> :
        <form onSubmit={handleSubmit(onSubmit)} className="pn-form">
          <button type="submit" disabled style={{ display: "none" }} aria-hidden="true">hidden</button>
          <div className='form-body'>
            <div className='form-row'>
              <h5 className="bp4-heading" style={{ padding: "5px" }}>Comparison Details</h5>
            </div>
            <div className='form-row' style={{ alignItems: "baseline" }}>
              <div className='input-ctr' style={{ flex: 2 }}>
                <label className='bp4-label' style={{ maxWidth: "initial" }}>
                  <div className='inline-flex'>
                    Comparison Name <span style={{ color: "crimson" }}>*</span>
                    <div className='control-ctr' style={{ flex: 1 }}>
                      <input aria-label="input field for comparison name" type="text" style={{ width: "100%" }} {...register("name")} className={`bp4-input ${errors?.name ? "bp4-intent-danger" : ""}`} />
                    </div>
                  </div>
                  {
                    currentTab.comparison.updatedAt &&
                      <div style={{marginTop: "5px", display: "flex"}}>
                        Date Modified
                        <div style={{ flex: 1, marginLeft: "5px", maxWidth: "200px"}}>
                          <input disabled aria-label="read only field for date modified" type="text" style={{ width: "100%" }} value={getDateTimeString(currentTab.comparison.updatedAt)}/>
                        </div>
                      </div>
                  }
                  <div className='errorText'>{errors?.name?.message}</div>
                </label>
              </div>
              {/* {!(selectedComparisonId || selectedComparisonIdForOpen) ? <div className='input-ctr' style={{ flex: 1 }}><Checkbox checked={addToFavorites} onClick={() => setAddToFavorites(!addToFavorites)}>Add Comparison To Favorites</Checkbox></div> : null} */}
              {/* <div className='input-ctr' style={{ flex: 1 }}><Checkbox checked={addToFavorites} onClick={() => setAddToFavorites(!addToFavorites)}>Add Comparison To Favorites</Checkbox></div> */}
              <div className='input-ctr' style={{ flex: 1 }}>
                <Switch labelElement={"Shared"} checked={sharedController.field.value} onChange={sharedController.field.onChange} />
              </div>
            </div>
            <div className='form-row'>

              <div className='form-column' style={{ flex: 1, ...formColumnStyle }}>
                <h5 className="bp4-heading" style={{ padding: "5px" }}>Comparison Groups</h5>
                <div className='input-ctr scrollable-groups'>
                  {groupsFieldArray.fields.map((v, index) => <ComparisonGroupControl
                    key={v.id}
                    initialGroup={{ name: v.name, color: v.color }}
                    removeGroup={() => {
                       updateComparedSamples(index)
                       groupsFieldArray.remove(index) 
                      }}
                    control={control}
                    index={index}
                    updatedGroups={updatedGroups}
                    setUpdatedGroups={setUpdatedGroups}
                  />)}
                  <Button icon={"plus"} onClick={() => groupsFieldArray.append({ name: "", color: "#000000" })}>Add Group</Button>
                </div>

              </div>
              <div className='form-column' style={formColumnStyle}>
                <h5 className="bp4-heading" style={{ padding: "5px" }}>Comparison Columns</h5>
                <div className='input-ctr'>
                  <SortContainer {...columnsController.field} errorMessage={columnsController.fieldState.error?.message} nationalDatabase={!!getValues("nationalDatabase")} />
                </div>
              </div>
            </div>
          </div>
          <div className="button-row">
            <Button aria-label="save new/updated comparison" type='submit' icon="floppy-disk" className={`bp4-intent-primary`}>Save</Button>
            <Button aria-label="close comparison window" onClick={() => {
              setShowComparisonForm(false)
            }}>Close</Button>
            {loading ? <div className='loading-ctr'><Spinner size={25}></Spinner></div> : null}
            <div style={{ marginLeft: '10px' }}>
              {submitCount > 0 && !isValid ? <span style={{ color: "red" }}>The form is not valid.</span> : null}
              {alert ? <span style={{ color: "red" }}>There was an error submitting.</span> : null}
              {errorMessage ? <span style={{ color: "red" }}>{errorMessage}</span> : null}
            </div>
          </div>
        </form>
      }
    </Dialog>
  </>
}